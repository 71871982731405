'use client'
import classNames from "classnames";
import styles from '../styles.module.scss'
import cargo from '../images/cargo.png';
import delivery from '../images/delivery.png';
import passenger from '../images/passenger.png';
import rent from '../images/rent.png';
import cargoSmall from '../images/cargo-small.png';
import deliverySmall from '../images/delivery-small.png';
import passengerSmall from '../images/passenger-small.png';
import rentSmall from '../images/rent-small.png';
import {useEffect, useRef, useState} from "react";


interface IProps {
  taskType: {
    tags: string[]
    type: string
    title: string
    description: string
  }
}


export const TaskTypeCard = ({taskType}: IProps) => {
  const [isFocus, setIsFocus] = useState(false)
  const [width, setWidth] = useState(typeof window !== "undefined" && window.innerWidth - 64)


  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };


    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const imageSrc = () => {
    if (width && width > 740) {
      if (taskType.type === 'cargo') return cargo.src
      if (taskType.type === 'rent') return rent.src
      if (taskType.type === 'passenger') return passenger.src
      if (taskType.type === 'delivery') return delivery.src
    } else {
      if (taskType.type === 'cargo') return cargoSmall.src
      if (taskType.type === 'rent') return rentSmall.src
      if (taskType.type === 'passenger') return passengerSmall.src
      if (taskType.type === 'delivery') return deliverySmall.src
    }
  }

  const containerRef = useRef<HTMLDivElement | null>(null)

  const [isDown, setIsDown] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDown(true)
    setStartX(e.pageX - (containerRef.current?.offsetLeft || 0))
    setScrollLeft(containerRef.current?.scrollLeft || 0)
  }

  const handleMouseUp = () => {
    setIsDown(false)
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - (containerRef.current?.offsetLeft || 0)
    const walk = x - startX
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft - walk
    }
  }

  // const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollLeft += e.deltaY;
  //   }
  // };



  return (
    <div
      className={classNames(styles.card)}
      onMouseOver={() => setIsFocus(true)}
      onMouseLeave={() => setIsFocus(false)}
      style={{backgroundImage: `url(${imageSrc()!})`}}
    >

      <p
        className={classNames(styles.title, isFocus || (width && width < 1240) ? styles.focus : '')}>{taskType.title}</p>

      <div className={classNames(styles.focusBlock, isFocus || (width && width < 1240) ? styles.focus : '')}>
        <div className={classNames(styles.focusBlockInfo)}>
          <div className={classNames(styles.line)}/>
          <p className={classNames(styles.description)}>{taskType.description}</p>

        </div>

      </div>
      <div
        className={classNames(styles.tagsWrapper, isFocus || (width && width < 1240) ? styles.focusTags : '')}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        // onWheel={handleWheel}
      >
        {taskType.tags.map((tag, index) => <div key={index} className={classNames(styles.tag)}><p>{tag}</p></div>)}
      </div>

    </div>
  )
}