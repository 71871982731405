'use client'
import s from './styles.module.scss'
import { useResizeWindow } from '@/hooks/useResizeWindow'
import Image from 'next/image'
import prizeDesktop from '@/images/PrizeBanner/prize-desktop.png'
import prizeMobile from '@/images/PrizeBanner/prize-mobile.png'

interface IProps {
  roleType: 'executor' | 'customer'
}

export const Decore = ({ roleType }: IProps) => {
  const windowWidth = useResizeWindow()
  const isMobile = windowWidth < 900
  return <div className={`${s.wrapper} ${isMobile ? s.mobile : s.desktop} ${s[roleType]}`}>
    {isMobile ? <Image src={prizeMobile} alt={'Приз розыгрыша'} width={700} height={243} /> :
      <Image src={prizeDesktop} alt={'Приз розыгрыша'} width={620} height={404} />}
  </div>
}