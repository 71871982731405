'use client'

import s from '../styles.module.scss'
import classNames from "classnames";
import plug from '../images/plugImg.png'

import 'moment/locale/ru'
import Link from "next/link";
import {Fragment, useEffect, useState} from "react";

import {RegularText} from "@/ui/Text";
import {declensionAfterNumerals} from "@/helpers/declensionAfterNumerals";

interface IProps {
    task: Record<string, any>
    roleType: 'customer' | 'executor'
    cardType: 'vehicle' | 'task'
    className?: string
    isMock?: boolean
}


const TaskDetailsVehicles = ({name, value}: { name: string, value?: string }) => {
    if (!value) return null

    return (
        <div className={classNames(s.taskDetailsVehicle)}>
            <p className={classNames(s.detailTitleVehicle)}>{`${name}:`}</p>
            <p className={classNames(s.detailVehicle)}>{value}</p>
        </div>
    )
}

const TaskDetails = ({details, detailTitle}: { detailTitle: string, details: string }) => {
    return (
        <>
            {details &&
                <div className={classNames(s.taskDetails)}>
                    <p className={classNames(s.detailTitle)}>{detailTitle}</p>
                    <p className={classNames(s.detail)}>{details}</p>
                </div>
            }
        </>
    )
}

export const TaskCard = ({task, roleType, cardType, className, isMock}: IProps) => {
    const [showNewClass, setShowNewClass] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setShowNewClass(true);

            setTimeout(() => {
                setShowNewClass(false);
            }, 2000);

        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    const mockVehicleSrc = `/assets/vehicles/mock/vehicles${task.id}.jpg`
    //const src = isMock && cardType === 'vehicle' ? mockVehicleSrc : task.photos?.length ? task.photos[0].public_url : plug.src


    const formatTimeAgo = (timestamp: Date) => {

        const now = new Date();
        const past = new Date(timestamp);
        const diff = Math.round((now.getTime() - past.getTime()) / 1000)

        if (diff < 1800) {
            return 'Только что';
        } else if (diff < 3600) {
            return `${30} ${declensionAfterNumerals(30, ['минуту', 'минуты', 'минут'])} назад`;
        } else if (diff < 86400) {
            const hours = Math.floor(diff / 3600);
            let viewHours
            if (hours <= 2) {
                viewHours = 1;
            } else if (hours <= 5) {
                viewHours = 3;
            } else if (hours <= 8) {
                viewHours = 9;
            } else if (hours <= 12) {
                viewHours = 12;
            } else if (hours <= 15) {
                viewHours = 15;
            } else if (hours <= 18) {
                viewHours = 18;
            } else if (hours <= 21) {
                viewHours = 21;
            }

            return `${viewHours} ${declensionAfterNumerals(viewHours!, ['час', 'часа', 'часов'])} назад`;
        } else if (diff < 2592000) {
            const days = Math.floor(diff / 86400);
            return `${days} ${declensionAfterNumerals(days, ['день', 'дня', 'дней'])} назад`;
        } else {
            return 'Месяц назад';
        }
    }

    const time = formatTimeAgo(task?.created_at?.datetime)

    const cursorStyle = task?.vehicleCharacteristics && task.vehicleCharacteristics.length > 0 ? 'pointer' : 'default'

    return (
        <article
            className={
                classNames(
                    s.card,
                    cardType === 'task' ? '' : s.cardVehicle,
                    showNewClass && task.isNew && s.new,
                    task?.isNewVehicle && s.newVehicle,
                    className
                )
            }

            style={{cursor: cursorStyle}}
        >
            <div className={classNames(s.time)}>
                {time}
            </div>

            {cardType === 'vehicle' && task.vehicleCharacteristics.length > 0 &&
                <div className={s.vehicleCharacteristics}>
                    {task.vehicleCharacteristics.map((item: { name: string, value: string }, index: number) =>
                        <Fragment key={index}>
                            <RegularText style={{color: 'var(--text-color-inverted)'}}>{item.name}</RegularText>
                            <RegularText style={{color: 'var(--text-color-inverted)'}}>{item.value}</RegularText>
                        </Fragment>)}
                </div>}

            {/*<img src={task.photos?.length ? task.photos[0].public_url : plug.src}*/}
            {/*     alt={cardType === 'vehicle' ? task?.modelName : 'plug'}*/}
            {/*/>*/}

            <img src={cardType === "vehicle" ? mockVehicleSrc : task?.image?.src ? task?.image?.src : plug.src}
                 alt={cardType === 'vehicle' ? task?.modelName : 'plug'}
            />
            <div className={classNames(s.cardContent)}>
                <h4 className={classNames(s.cardTitle)}>{task.title}</h4>
                <p className={classNames(s.cardDescription)}>{task.description}</p>
                {cardType === 'task' &&
                    <>
                        <TaskDetails detailTitle={'Тип работ:'} details={task.type ? task.type.name : ''}/>
                        <TaskDetails detailTitle={'Локация:'} details={'Саратовская область'}/>
                    </>
                }

                {cardType === 'vehicle' &&
                    <>
                        <TaskDetailsVehicles name={'Тип техники'} value={task?.vehicleType}/>
                        <TaskDetailsVehicles name={'Марка/Модель'} value={task?.modelName}/>
                        <TaskDetailsVehicles name={'Тип задач'} value={task?.taskType}/>
                        <TaskDetailsVehicles name={'Локация'} value={task?.location}/>
                    </>
                }


                {cardType === 'task' &&
                    <div className={classNames(s.costBlock)}>
                        <p
                            className={classNames(s.cost)}
                            style={{color: `var(--primary-${roleType}-color)`}}
                        >
                            {task.cost ? `${task.cost} руб` : ''}
                        </p>
                        <Link
                            href={'/executor'}
                            style={{color: `var(--primary-${roleType}-color)`}}
                            className={classNames(s.link)}
                        >
                            Стать исполнителем
                        </Link>
                    </div>
                }

            </div>
        </article>
    )
}
