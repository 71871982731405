import {beginningArray} from "@/sections/Beginning/constants";
import {Slider as SliderComponent} from "@/sections/Beginning/components/Slider";
import classNames from "classnames";
import s from '../styles.module.scss'

interface IProps {
  roleType: 'executor' | 'customer'
  width?: number
}
export const MobileCards = ({roleType, width}: IProps) => {
  return (
    <div className={classNames(s.mobileFlex)}>
      {beginningArray.map((el, index) => {
        return (
          <SliderComponent key={index} el={el} roleType={roleType} index={index} width={width} />
        )
      })}
    </div>
  )
}