import s from '../styles.module.scss'
import classNames from "classnames";
import registration from '../images/registration.png'
import addTask from '../images/addtask.png'
import getApplication from '../images/getApplication.png'
import chooseExecutor from '../images/chooseExecutor.png'
import acceptWork from '../images/acceptWork.png'
import registrationSmall from '../images/registration-small.png'
import addTaskSmall from '../images/addtask-small.png'
import getApplicationSmall from '../images/getApplication-small.png'
import chooseExecutorSmall from '../images/chooseExecutor-small.png'
import acceptWorkSmall from '../images/acceptWork-small.png'
import Image from "next/image";

interface IProps {
  el:  {title: string, description: string}
  roleType: 'customer' | 'executor'
  index: number
  width?: number
}
export const Slider = ({el, roleType, index, width}: IProps) => {
  const getImages = () => {
    if(width && width > 600) {
      if(index === 0) return registration
      if(index === 1) return addTask
      if(index === 2) return getApplication
      if(index === 3) return chooseExecutor
      if(index === 4) return acceptWork
    }
    if(width && width < 600) {
      if(index === 0) return registrationSmall
      if(index === 1) return addTaskSmall
      if(index === 2) return getApplicationSmall
      if(index === 3) return chooseExecutorSmall
      if(index === 4) return acceptWorkSmall
    }
  }

  return (
    <div className={classNames(s.slider)}>
      <div className={classNames(s.flex)}>
        <div className={classNames(s.info)}>
          <p
            className={classNames(s.counter)}
            style={{color: `var(--primary-${roleType}-color)`}}
          >
            {`0${index+1}`}
          </p>
          <p className={classNames(s.titleSlider)}>{el.title}</p>
          <p className={classNames(s.descriptionSlider)}>{el.description}</p>
        </div>
        <Image src={getImages()!} alt={'slider image'}/>
      </div>
    </div>
  )
}