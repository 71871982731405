import {vehiclesTypes} from "@/sections/Vehicles/constants";
import s from '../styles.module.scss'
import classNames from "classnames";
import Image from "next/image";
import Slider from "react-slick";
import {RoleType} from "@/interfaces/RoleType";

interface IProps {
    roleType: 'customer' | 'executor'
}

const colors = {
    executor: '#F38E30',
    customer: '#006CEC'
}

function SampleArrow(props: any) {
    const {customStyle, onClick, roleType} = props;
    return (
        <button
            className={classNames(s.slickArrow)}
            onClick={onClick}
            style={customStyle}
        >
            <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 21.5L12.0781 12.2682C12.5579 11.8684 12.5579 11.1316 12.0781 10.7318L1 1.5"
                      stroke={colors[roleType as RoleType]}
                      strokeWidth="2" strokeLinecap="round"/>
            </svg>
        </button>
    );
}

export const VehicleTypesList = ({roleType}: IProps) => {

    const settings = {
        slidesToShow: 9,
        slidesToScroll: 9,
        dots: false,
        infinite: true,
        nextArrow: <SampleArrow customStyle={{right: '0'}} roleType={roleType}/>,
        prevArrow: <SampleArrow customStyle={{transform: `rotate(180deg)`, left: '0'}} roleType={roleType}/>,
        responsive: [
            {

                breakpoint: 1510,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    };

    return (
        <div className={classNames(s.types)}>
            <Slider
                {...settings}
                className={s.slickSlider}
            >
                {vehiclesTypes.map((el, i) => {
                    return (
                        <div key={i}>
                            <div className={classNames(s.typeWrapper)}>
                                <div className={classNames(s.type)}>
                                    <Image src={`/assets/vehicles/${roleType}/v${i + 1}.svg`} alt={el} width={80}
                                           height={80}/>
                                </div>
                                <p style={{textAlign: 'center'}}>{el}</p>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}